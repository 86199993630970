import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import styles from "@/components/core/Checkbox/index.module.scss"
import checkboxTick from "@/public/icons/checkbox-tick.svg"
import { getConfig } from "@/constants/config"

const Checkbox = props => {
  const [config, setConfig] = useState({})
  const { textClassName = "checkbox_default", inline = false } = props
  useEffect(() => {
    getConfig()
      .then(config => {
        setConfig(config?.general)
      })
      .catch(err => console.log(err))
  }, [])
  const handleCheck = e => {
    e.target.checked = !props.checked
    if (e.keyCode === 32 || e.keyCode === 13) props.onChange(e)
  }

  return (
    <div
      className={`${styles.checkboxWrapper}${
        inline ? ` ${styles.inlineCheckbox}` : ""
      }`}
    >
      <label
        htmlFor={props.id}
        aria-checked={props.checked}
        tabIndex={props.tabIndex ?? 0}
        role={props.role ?? "checkbox"}
        aria-label={props.checkBoxAria ?? props.aria}
        className={cx({
          checkbox: true,
          "checkbox--swatch": props.swatch,
          "checkbox--black": props.type === "black",
        })}
        onKeyDown={handleCheck}
      >
        <input
          data-gbh-data-layer={props.analyticsData || null}
          data-gbh-data-layer-custom={props.analyticsDataCustom || null}
          className={props.analyticsData ? "gbh-data-layer" : ""}
          type="checkbox"
          name={props.id}
          id={props.id}
          onChange={e => props.onChange(e)}
          checked={props.checked}
          disabled={props.disabled}
        />
        <span className="checkbox__border">
          <div className="checkbox__marker">
            <img
              className="checkbox__marker-icon"
              src={checkboxTick.src}
              alt="Checkbox"
            />
          </div>
        </span>
        {config?.swatchUrl ? (
          <div
            className="checkbox__swatch"
            style={{
              backgroundImage: `url("${config?.swatchUrl}${props?.swatch}?$PLPSwatch_Mobile50$")`,
            }}
          ></div>
        ) : null}
        <span className={textClassName}>{props.value}</span>
      </label>
    </div>
  )
}

export default Checkbox

Checkbox.protoTypes = {
  textClassName: PropTypes.string,
  checked: PropTypes.bool,
  id: PropTypes.string,
  tabIndex: PropTypes.number,
  role: PropTypes.string,
  checkBoxAria: PropTypes.string,
  aria: PropTypes.string,
  swatch: PropTypes.string,
  type: PropTypes.string,
  analyticsData: PropTypes.object,
  analyticsDataCustom: PropTypes.object,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
}
