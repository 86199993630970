import { store } from "@/store"
import { apim } from "@/constants/api"
import { getQuantityCount } from "@/utils/cart"
import { setCart, getCart, setCartCount } from "@/store/features/cartSlice"
import { getPincode, getETA } from "@/components/cart/cartHelper"
import { showToast, showPreviewCart } from "@/store/features/genericSlice"
import { changeBundleQty } from "@/utils/removeCart"

export async function addToCart(
  items,
  showPreview,
  message,
  callback,
  taxCalculate,
  isSharedCart = false,
  isPreviewCart = false
) {
  let actions = []
  let userZipCode
  await store
    .dispatch(getCart())
    .unwrap()
    .then(res => {
      store.dispatch(setCart(res))
    })
  const { cart: { cart: { lineItems = [], customLineItems = [] } = {} } = {} } =
    store.getState()
  let sku = ""
  for (let product of items) {
    const associatedProduct = items.find(
      item =>
        item?.isInstallService &&
        item?.installServiceName === product?.sku &&
        item?.sku === product?.custom?.fields?.associatedProduct
    )
    const isInCart = product.isBundle
      ? customLineItems.find(
          item => item.custom?.fields?.bundleSku === product.sku
        )
      : !product?.isInstallService &&
        associatedProduct &&
        associatedProduct?.sku
      ? lineItems?.find(
          item =>
            !item.custom.fields.isBundleChildItem &&
            item?.variant?.sku === product?.sku &&
            item?.custom?.fields?.associatedProduct === associatedProduct?.sku
        )
      : lineItems?.find(
          item =>
            !item.custom.fields.isBundleChildItem &&
            item?.variant?.sku === product?.sku
        )

    const lineItemId = isInCart?.id
    let warehouses = [JSON.stringify(product?.warehouses)]

    const warehouse = isPreviewCart
      ? product?.warehouses
      : isSharedCart
      ? product.custom?.fields?.warehouses
      : warehouses

    let customObj = {
      type: { key: "cartLineItemAdditionalInfo", typeId: "type" },
    }

    if (product.isInstallService && warehouse) {
      sku = product?.sku
      customObj = {
        ...customObj,
        fields: {
          warehouses: warehouse,
          associatedService: product?.installServiceName,
          associatedServiceQty: product?.installServiceQty,
        },
      }
    } else {
      if (associatedProduct && associatedProduct?.sku) {
        customObj = {
          type: {
            key: "cartLineItemAdditionalInfo",
            typeId: "type",
          },
          fields: {
            associatedProduct: associatedProduct.sku,
          },
        }
      } else {
        customObj = {
          ...customObj,
          fields: {
            warehouses: warehouse,
          },
        }
      }
    }
    if (!isPreviewCart && isInCart) {
      if (product.isBundle) {
        let pincode = await getPincode()

        const handleStockError = () => {
          const noStockError = new Event("set-stock-alert")
          document.dispatchEvent(noStockError)
        }

        const warehouses = await getETA(
          product.sku,
          null,
          isInCart?.quantity + product?.quantity,
          pincode,
          false,
          true,
          handleStockError
        )

        if (warehouses) {
          const payload = changeBundleQty(
            lineItemId,
            product.sku,
            isInCart?.quantity + product?.quantity,
            warehouses,
            product?.isInstallService,
            true
          )

          actions = payload

          if (product.isInstallService) {
            const installService = lineItems.find(item => {
              if (item.custom?.fields?.parentBundleSku === product.sku) {
                const category = item.variant?.attributes?.find(
                  attr => attr.name === "ProductLocalCategory"
                )
                return category?.value === "Install Services"
              }
            })

            if (installService) {
              actions.push({
                action: "changeBundleSKUQuantity",
                lineItemId: installService.id,
                quantity: installService.quantity + product?.quantity,
              })
            } else {
              const installServiceSku =
                product.warehouses?.bundle?.installationService?.name

              if (installServiceSku) {
                actions.push({
                  action: "addBundleSKUQuantity",
                  customLineItemId: lineItemId,
                  installServiceSku: installServiceSku,
                  quantity: product?.quantity,
                })
              }
            }
          }
        }
      } else {
        actions.push({
          action: "changeLineItemQuantity",
          lineItemId: lineItemId,
          quantity: isInCart?.quantity + product?.quantity,
        })
      }

      if (product?.isInstallService && !product.isBundle) {
        const installService = lineItems?.find(
          item =>
            !item.custom?.fields?.isBundleChildItem &&
            item?.variant?.sku === product?.sku
        )
        const productLineItemId = installService?.id
        actions.push({
          action: "setLineItemCustomType",
          lineItemId: productLineItemId,
          type: {
            key: "cartLineItemAdditionalInfo",
            typeId: "type",
          },
          fields: {
            associatedService:
              installService?.custom?.fields?.associatedService ||
              product?.installServiceName,
            associatedServiceQty:
              installService?.custom?.fields?.associatedServiceQty +
                product?.quantity || product?.installServiceQty,
            warehouses: installService?.custom?.fields?.warehouses || warehouse,
          },
        })
      }
    } else {
      let bundleCustomObj = null

      if (isSharedCart) {
        if (product.isBundle) {
          let pincode = await getPincode()

          const warehouses = await getETA(
            product.sku,
            null,
            product?.quantity,
            pincode,
            false,
            true
          )
          const payload = changeBundleQty(
            lineItemId,
            product.sku,
            isInCart?.quantity + product?.quantity,
            warehouses,
            product?.isInstallService,
            true
          )

          bundleCustomObj = payload[0].custom
        }
      }
      const payload = {
        action: product.isBundle ? "addBundleItem" : "addLineItem",
        sku: product.sku,
        quantity: product.quantity,
        custom: bundleCustomObj ?? customObj,
      }
      if (product?.isBundle && product?.isInstallService) {
        payload.includeInstallationService = true
      }
      actions.push(payload)
    }
    userZipCode = product?.userZipCode
  }

  const {
    cart: {
      cart: { version = 0, shippingAddress: { postalCode = "" } = {} } = {},
    } = {},
  } = store.getState()
  if (isPreviewCart) {
    return actions
  } else {
    if (actions?.length) {
      updateCart(
        version,
        actions,
        taxCalculate,
        postalCode,
        message,
        callback,
        userZipCode,
        isSharedCart,
        showPreview
      )
    }
  }
}

export async function updateCart(
  version,
  actions = [],
  taxCalculate,
  postalCode,
  message = "",
  callback = () => {},
  userZipCode = "",
  isSharedCart = false,
  showPreview = false
) {
  const { isAuth } = store.getState()
  const { cart: { cart: { id = "" } = {} } = {} } = store.getState()
  const body = {
    version: version,
    actions: actions,
  }
  if (userZipCode) {
    body.servicesku = true
    body.zipCode = userZipCode
  }
  let atcStatus
  try {
    const response = await apim.post(
      `/cart/items?cartId=${id}&expand=calculation`,
      body
    )
    const { status, data } = response
    if (status === 200) {
      if (!isSharedCart) {
        store.dispatch(setCart(data))
      }
      const lineItems = data.lineItems || []
      const customLineItems = data.customLineItems || []
      store.dispatch(
        setCartCount(getQuantityCount([...lineItems, ...customLineItems]))
      )
      if (message) store.dispatch(showToast(message))
      if (callback) callback({ status, message: "success", data })
      if (showPreview) {
        store.dispatch(showPreviewCart())
      }

      if (!isAuth && data.anonymousId)
        localStorage.setItem("anonymousId", data.anonymousId)
    } else {
      store.dispatch(showToast(i18n.t("kf.cart.label.addToCartErrorMsg")))
    }
  } catch (err) {
    const { response: { status } = {}, message } = err
    if (callback) callback({ status, message })
    atcStatus = { status, message }
  }
  return atcStatus
}

export const attachWarehouseData = (items, parentItem) => {
  if (!window.etaCache) {
    console.warn("Error: ETA cache not available")
    return items
  }

  return items
    .map(item => {
      try {
        if (item.sku && window.etaCache[item.sku]) {
          const warehouseData = window.etaCache[item.sku]?.warehouse

          if (warehouseData) {
            return { ...item, warehouses: warehouseData }
          }
        }
      } catch (ex) {
        console.error(`Error in getting warehouse for SKU ${item.sku}`)
      }
      return item
    })
    .map(item => {
      item.quantity = parentItem.quantity
      return item
    })
}
